import AppBar from "@mui/material/AppBar";
import { useTheme } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/system/Box";
import type { ReactNode } from "react";
import React, { useState } from "react";

import { Header } from "./Header/Header";
import { Main } from "./Layout.styles";
import { Sidebar } from "./Sidebar/Sidebar";

interface MainLayoutProps {
  children: ReactNode;
}

export function Layout(props: MainLayoutProps): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const theme = useTheme();

  const handleDrawerToggle = () => setDrawerOpen((prev) => !prev);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create("width") : "none",
        }}
      >
        <Toolbar>
          <Header onDrawerToggle={handleDrawerToggle} />
        </Toolbar>
      </AppBar>

      <Sidebar isDrawerOpen={drawerOpen} onToggleDrawer={handleDrawerToggle} />

      <Main theme={theme} open={drawerOpen}>
        {props.children}
      </Main>
    </Box>
  );
}
