import { useEffect, useState } from "react";

import { useAuthStore } from "src/context/authStore";
import { configCatClient } from "src/flagClient";

export function useIsFlagEnabled(flag: string) {
  const [isFlagEnabled] = useFlag(flag, false);

  return isFlagEnabled;
}

/**
 * TODO: load flags first thing when initialising the app and then refresh once a minute,
 * like we do in the mobile app, so the rest of the app doesn't have to wait for flags to load.
 */
export function useFlag<T>(flag: string, defaultValue: T) {
  const [state, setState] = useState({ loading: true, value: defaultValue });

  const authState = useAuthStore.getState();

  useEffect(() => {
    const user = {
      identifier: authState.username,
      email: authState.username,
      custom: {
        partnerid: authState.partnerId ?? "",
      },
    };

    configCatClient.getValueAsync(flag, defaultValue, user).then((value: T) => {
      setState({ loading: false, value });
    });
  }, [flag, defaultValue, authState]);

  return [state.value, state.loading] as const;
}
