import { createSvgIcon } from "@mui/material/utils";

export const TradePersonIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 14.2893 4.96156 16.3539 6.50282 17.8122C7.31432 16.1472 9.02319 15 11 15H13.5C15.342 15 16.9256 16.1068 17.6219 17.6916C19.0901 16.2412 20 14.2269 20 12C20 7.58172 16.4183 4 12 4ZM15.9422 18.9629C15.6964 17.8403 14.6963 17 13.5 17H11C9.68573 17 8.56885 17.8451 8.16317 19.0216C9.30226 19.6454 10.6097 20 12 20C13.4333 20 14.7786 19.6231 15.9422 18.9629ZM12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10C16 12.2091 14.2091 14 12 14ZM12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8Z"
    fill="currentColor"
  />,
  "Tradeperson",
);
