import Menu from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import React from "react";
import { useIsFetching, useIsMutating } from "react-query";

import { Logo } from "src/components/logo";
import { logout } from "src/utils/auth";

interface HeaderProps {
  onDrawerToggle: () => void;
}

export function Header(props: HeaderProps) {
  const queriesInProgress = useIsFetching();
  const mutationsInProgress = useIsMutating();
  const isLoading = queriesInProgress > 0 || mutationsInProgress > 0;
  const theme = useTheme();

  return (
    <>
      {isLoading && (
        <Box position="absolute" top={0} width="100%">
          <LinearProgress />
        </Box>
      )}

      <Box
        sx={{
          width: 228,
          paddingLeft: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Link href="/" passHref legacyBehavior>
          <Stack direction="row" gap={1} alignItems="center">
            <Logo width={26} height={22} color={theme.palette.primary.dark} />
            <Typography variant="h4" color={theme.palette.primary.dark}>
              Kantan
            </Typography>
          </Stack>
        </Link>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: "hidden",
            transition: "all .2s ease-in-out",
            background:
              theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.primary.light,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.dark,
            "&:hover": {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.light
                  : theme.palette.secondary.light,
            },
          }}
          onClick={props.onDrawerToggle}
          color="inherit"
        >
          <Menu />
        </Avatar>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ display: { xs: "none", sm: "block" } }}>{/* <LocalizationSection /> */}</Box>

      <Button onClick={logout} color="primary" variant="outlined" size="small">
        Logout
      </Button>
    </>
  );
}
