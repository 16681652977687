import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Link from "next/link";

import type { NavItemType } from "../types";

interface NavItemProps {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: NavItemProps) => {
  return (
    <Link href={item.href} passHref legacyBehavior>
      <ListItemButton
        component="a"
        sx={{
          borderRadius: 1,
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`,
        }}
      >
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant={"h5"} color="inherit">
              {item.title}
            </Typography>
          }
        />
      </ListItemButton>
    </Link>
  );
};

export default NavItem;
