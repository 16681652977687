import { createSvgIcon } from "@mui/material/utils";

export const SpannerIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2028 2.14281C13.4948 1.40944 10.6108 2.16225 8.62525 4.14784C6.80315 5.96994 6.01389 8.55476 6.47278 11.0733L3.38052 14.1655C1.62316 15.9229 1.62316 18.7722 3.38052 20.5295C5.13788 22.2869 7.98712 22.2869 9.74448 20.5295L12.8367 17.4372C15.3553 17.8961 17.9401 17.1069 19.7622 15.2848C21.7341 13.3129 22.491 10.4543 21.7813 7.76005C21.7717 7.72349 21.7618 7.68699 21.7516 7.65055C21.5589 6.95944 20.693 6.73211 20.1857 7.23944L17.4789 9.94622L14.5723 9.34131L14.0105 6.48166L16.7463 3.74592C17.2503 3.24191 17.0297 2.38208 16.3454 2.18287C16.298 2.16907 16.2504 2.15571 16.2028 2.14281ZM9.95108 5.47367C11.0669 4.35781 12.5587 3.75281 14.098 3.74255L12.4727 5.36783C12.1635 5.67701 12.0286 6.11986 12.1129 6.54891L12.8044 10.069C12.9061 10.5868 13.3082 10.9934 13.8249 11.1009L17.3944 11.8438C17.8275 11.934 18.277 11.7998 18.5899 11.4869L20.166 9.91079C20.1311 11.4155 19.5281 12.8672 18.4364 13.959C17.018 15.3773 14.9883 15.974 13.0195 15.5626L12.2256 15.3967L8.41866 19.2037C7.39353 20.2288 5.73147 20.2288 4.70634 19.2037C3.68122 18.1786 3.68122 16.5165 4.70634 15.4914L8.51329 11.6844L8.34739 10.8905C7.936 8.92173 8.53273 6.89202 9.95108 5.47367ZM6.125 16.3049C5.84886 16.3049 5.625 16.5288 5.625 16.8049V17.6799C5.625 17.9561 5.84886 18.1799 6.125 18.1799H7C7.27614 18.1799 7.5 17.9561 7.5 17.6799V16.8049C7.5 16.5288 7.27614 16.3049 7 16.3049H6.125Z"
      fill="#292B2E"
    />
  </svg>,
  "Spanner",
);
