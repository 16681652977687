// eslint-disable-next-line
import { styled } from "@mui/system";

export const Logo = styled((props?: { width: number; height: number; color?: string }) => {
  return (
    <svg
      width="120"
      height="99"
      viewBox="0 0 120 99"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4542 1.50038C57.8811 -0.50333 62.1168 -0.499904 65.5405 1.50935L114.055 29.9816C117.737 32.1422 120 36.1023 120 40.384L120 90.2967C120 94.7331 116.418 98.3294 112 98.3294L92 98.4C87.5818 98.4 84 94.7331 84 90.2967L84 62.1535C84 59.6423 82.6657 57.322 80.4998 56.0665L63.9998 46.502C61.5247 45.0672 58.4753 45.0672 56.0002 46.5019L39.5001 56.0664C37.3342 57.3219 35.9999 59.6423 35.9999 62.1535L36.0001 90.2967C36.0001 94.733 32.4184 98.3294 28.0001 98.3294H8.00011C3.58184 98.3294 0.00012207 94.733 0.00012207 90.2967L0 40.2667C0 35.9781 2.27032 32.0127 5.96131 29.8545L54.4542 1.50038Z"
      />
      <path d="M65.884 67.5926C65.884 64.5426 68.3464 62.0701 71.384 62.0701C74.4216 62.0701 76.884 64.5426 76.884 67.5926C76.884 70.6425 74.4216 73.115 71.384 73.115C68.3464 73.115 65.884 70.6425 65.884 67.5926Z" />
    </svg>
  );
})``;
