import { useAuthStore } from "../context/authStore";

type GroupType = "admin" | "trusted_partner" | "restricted_partner" | "unknown";

type UserPermission = {
  group: GroupType;
  permissions: string[];
  isRestrictedPartner: boolean;
};

export function usePermissions(): UserPermission {
  const { isAdmin, permissions } = useAuthStore((state) => state);
  let group: GroupType;
  if (isAdmin) {
    group = "admin";
  } else if (
    permissions.includes("JOBS") &&
    permissions.includes("APPOINTMENTS") &&
    permissions.includes("TRADESPEOPLE") &&
    permissions.length === 3
  ) {
    group = "trusted_partner";
  } else if (permissions.includes("JOBS") && permissions.length === 1) {
    group = "restricted_partner";
  } else {
    group = "unknown";
  }
  const isRestrictedPartner = group === "restricted_partner";

  return {
    group,
    permissions,
    isRestrictedPartner,
  };
}
